export default {
    props: { trackings: Object,
        collapse:Boolean },
    computed: {
        
    }, 
    methods:{
      
    }

}