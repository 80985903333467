/* eslint-disable */
import VueSelect from 'vue-select'
import store from '@/store'
import apiAIT from '@/common/ateneaInvoiceToolsApi'

export default {
  components: {
      VueSelect,
  },
  data: () => {
    return {
      project_name: null,
      user: store.getters.getUser,
      projects: [],
      selected_department: null,
      departments: [],
    }
  },

  created() {
    this.get_projects()
    this.get_departments()
  },
  computed: {
  },
  methods: {
    async save() {
      if (!this.project_name) {
        alert("Debe indicar un nombre para el proyecto")
        return
      }
      if (!this.selected_department) {
        alert("Debe seleccionar un departamento")
        return
      }
      if (!this.user) {
        alert("El usuario no puede estar vacío")
        return
      }
      console.log("Guardar", this.project_name, this.user.user.id)
      const loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        onCancel: this.onCancel,
        loader: 'dots'
      });

      let params = { user_id: this.user.user.id, label: this.project_name, department_id: this.selected_department.id }
      console.log("params", params)
      await apiAIT.postProjects(params).then((r) => {
        console.log(r)
        if (r) {
          alert("Proyecto guardado")
          this.department_name = null
        }
      }).catch((err) => {
        console.error(err)
        alert("Se produjo un error guardando el proyecto")

      }).finally(() => {
        loader.hide()
      })
      this.get_projects()

    },
    async get_projects() {
      const loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        onCancel: this.onCancel,
        loader: 'dots'
      });
      await apiAIT.getProjects().then((r) => {
        this.projects = r
      }).catch((err) => {
        console.error(err)
        alert("Se produjo un error al obtener los proyectos")
      }).finally(() => {
        loader.hide()
      })
    },
    async get_departments() {
      const loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        onCancel: this.onCancel,
        loader: 'dots'
      });
      await apiAIT.getDepartments().then((r) => {
        this.departments = r
        this.selected_department = null
        this.project_name = null
      }).catch((err) => {
        console.error(err)
        alert("Se produjo un error al obtener los departamentos")
      }).finally(() => {
        loader.hide()
      })
    },

  }
}
