<template>
    <div class="time-tracking">
        <div class="center">
            <UserProfile :user=this.user></UserProfile>
        </div>
    </div>
    <div class="date-tracking">
        <VueDatePicker v-model="date" type="date" :enable-time-picker="false" locale="es" :preview-format="this.format"
            :format="this.format" @update:model-value="onChangeDatePicker"></VueDatePicker>
    </div>
    <div class="">
        <TableTrackings :collapse=true  :trackings=this.user_tracking></TableTrackings>
    </div>
    <div class="resume-tracking">
            <ResumeTrackings :array_minutes_hours=this.array_minutes_hours_tracking></ResumeTrackings>
    </div>
    <div class="form-tracking">

        <div class="list">
            <div v-for="(item, index) in deparments_and_projects" :key="index">
                <div class="department">
                    <h4 class="text-start department-title">Departamento: {{ item.name }}</h4>
                    <h5 v-if="item.projects.length > 0" class="text-start projects-title">Proyectos:</h5>
                    <h5 v-else class="text-start text-danger">Sin proyectos asignados</h5>
                    <div v-for="(project, index_project) in item.projects" :key="index_project">
                        <div class="project">
                            <div class="row project-title">
                                <div class="col-sm text-start">{{ project.name }} </div>
                            </div>
                            <div class="row">
                                <div class="col-sm text-md-start">
                                    <label class="lbl-timetracking">Inicio</label>
                                    <input v-maska data-maska="##:##" v-model="project.start" placeholder="00:00"
                                        @blur="handleBlur(index, index_project)" />
                                </div>
                                <div class="col-sm text-md-start">
                                    <label class="lbl-timetracking">Final</label><input v-maska data-maska="##:##"
                                        v-model="project.end" placeholder="00:00" @blur="handleBlur(index, index_project)"
                                        />
                                </div>
                                <div class="col-sm text-md-start">
                                    <label class="lbl-timetracking">Minutos</label><input v-on:keypress="NumbersOnly"
                                        v-model="project.minutes" @paste.prevent/>
                                </div>
                                <div class="col-sm text-md-start">
                                    <label class="lbl-timetracking invisible">Comentarios</label>
                                    <input v-model="project.notes" placeholder="Comentarios" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <button type="button" class="btn btn-success" @click="save">Guardar horas</button>
    </div>
</template>
  

<style src="./timetracking.css" scoped></style>
<script src="./timetracking.view.js"></script>