<template>
    <div class="card mb-3" id="user_profile">
        <div class="card-img"></div>
        <div class="card-body">
            <h5 class="card-title">{{ user.user.name }}</h5>
            <h6 v-if="user.admin&&user.admin.superadmin" class="card-title">Superadministrador</h6>
            <h6 v-else-if="user.admin" class="card-title">Administrador</h6>
            <div class="row card-text">
                <div class="col-sm text-md-start">
                    <label class="lbl-profile text-success fw-bold">Nombre:</label> {{user.user.name}}
                </div>
                <div class="col-sm text-md-center">
                    <label class="lbl-profile text-success fw-bold">Email:</label> {{user.user.email}}
                </div>
                <div class="col-sm text-md-end">
                    <label class="lbl-profile text-success fw-bold">Activo:</label> {{get_active}}
                </div>
            </div>
            <div class="row card-text">
                <div class="col-sm text-md-start">
                    <label class="lbl-profile text-success fw-bold">Creado:</label> {{get_created}}
                </div>
                <div class="col-sm text-md-center">
                    <label class="lbl-profile text-success fw-bold">Actualizado:</label> {{get_updated}}
                </div>
                <div class="col-sm text-md-end">
                    <label class="lbl-profile text-success fw-bold">Nº Departamentos:</label> {{user.departments.length}}
                </div>
            </div>
        </div>
    </div>
</template>

<style src="./userprofile.css" scoped></style>
<script src="./userprofile.view.js"></script>