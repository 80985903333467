/* eslint-disable */
import store from '@/store'
import apiAIT from '@/common/ateneaInvoiceToolsApi'

export default {
  name: 'admin-departments',
  data: () => {
    return {
      department_name: null,
      user: store.getters.getUser,
      departments:[]
    }
  },

  created() {
    this.get_deparments()
  },
  computed: {
  },
  methods: {
    async save() {
      if (!this.department_name) {
        alert("Debe indicar un nombre para el departamento")
        return
      }
      if (!this.user) {
        alert("El usuario no puede estar vacío")
        return
      }
      console.log("Guardar", this.department_name, this.user.user.id)
      const loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        onCancel: this.onCancel,
        loader: 'dots'
      });

      let params = { user_id: this.user.user.id, label: this.department_name }
      await apiAIT.postDepartments(params).then((r)=>{
        console.log(r)
        if(r){
          alert("Departamento guardado")
          this.department_name=null
        }
      }).catch((err)=>{
        console.error(err)
        alert("Se produjo un error guardando el departamento")

      }).finally(()=>{
        loader.hide()
      })
      this.get_deparments()

    },
    async get_deparments(){
      const loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        onCancel: this.onCancel,
        loader: 'dots'
      });
      await apiAIT.getDepartments().then((r)=>{
        this.departments = r
      }).catch((err)=>{
        console.error(err)
        alert("Se produjo un error al obtener los departamentos")
      }).finally(()=>{
        loader.hide()
      })
    }

  }
}
