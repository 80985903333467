import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/homepage/HomePage.vue'
import ErrorPage from '@/views/errorpage/ErrorPage.vue'
import TimeTracking from '@/views/timetracking/TimeTracking.vue'
import TimerView from '@/views/timerview/TimerView.vue'
import AuthController from '@/common/auth'
import store from '@/store'
import InvoiceProcess from '@/views/invoiceprocess/InvoiceProcess.vue'
//ADMIN
import AdminUsers from '@/views/admin/adminusers/AdminUsers'
import AdminDepartments from '@/views/admin/admindepartments/AdminDepartments'
import AdminProjects from '@/views/admin/adminprojects/AdminProjects'
import AdminTimeTrackingUsers from '@/views/admin/timetrackingusers/TimeTrackingUsers'
const routes = [
    {
        path: '/:pathMatch(.*)*', /* oopsie */
        redirect: "/",
    },
    {
        path: "/",
        name: "Home page",
        component: HomePage,
    },
    {
        path: "/error-auth",
        name: "error-auth",
        component: ErrorPage,
        meta: {
            requiresError: true
        }
    },
    {
        path: "/logout",
        name: "logout",
        beforeEnter(to, from, next) {
            AuthController.sign_out()
            if (from.meta.requiresAuth) {
                return next('/');
            }
            if (store.getters.has_errors) {
                return next('/error-auth');
            }
            return next('/');
        }
    }, {
        path: "/signin",
        name: "SignIn",
        beforeEnter(to, from, next) {
            AuthController.sign_in()
            return next('/');
        },
        meta: {
            hideForAuth: true
        },
    },
    {
        path: "/time-tracking",
        name: "timetracking",
        component: TimeTracking,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/timer",
        name: "timer",
        component: TimerView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/users",
        name: "adminusers",
        component: AdminUsers,
        meta: {
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: "/admin/departments",
        name: "admindepartments",
        component: AdminDepartments,
        meta: {
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: "/admin/projects",
        name: "adminprojects",
        component: AdminProjects,
        meta: {
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: "/invoices",
        name: "invoiceprocess",
        component: InvoiceProcess,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: "/admin/time-tracking-users",
        name: "timetrackingusers",
        component: AdminTimeTrackingUsers,
        meta: {
            requiresAuth: true
        }
    },

]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;