/* eslint-disable */
import { conf, auth } from './aws-exports'
import { Amplify, Auth, Hub } from 'aws-amplify';
import store from '@/store/index'
import login from '@/common/login'

export const inicializar = async () => {
    // console.log(conf)
    // console.log(auth)
    let amply = Amplify.configure({ Auth: conf });
    let authi = Auth.configure({ oauth: auth })
    console.log("amply", amply)
    console.log("authi", authi)
    Hub.listen('auth', async ({ payload: { event, data } }) => {
        switch (event) {
            case 'signIn':
                console.log('sign in', event, data)
                var token = await data.signInUserSession.getAccessToken().getJwtToken();
                var user_name = data.username

                // console.log("logged", token, user_name)
                console.log("logged", user_name)
                var user = await Auth.currentUserInfo()
                console.log("User", user)
                if (user != null && user != undefined) {
                    var email = user.attributes.email
                    var name = user.attributes.name
                    var sub = user.attributes.sub
                    login.log_in(user_name, name, email, token, sub)
                }

                break
            case 'signOut':
                console.log('sign out')
                // this.setState({ user: null })
                break
            default:
                console.log(event, data)
        }
    })

    Auth.currentAuthenticatedUser()
        .then(async (currentUser) => {
            // console.log("Current user", currentUser)
            var f = await currentUser.signInUserSession.getAccessToken().getJwtToken();
            // console.log(f)
        }
        )
        .catch((e) => console.log("Not signed in", e));


}

export const auth_federated_sign_in = async () => {
    Auth.federatedSignIn()
}

export const sign_out = async () => {
    Auth.signOut()
    store.commit('logout')
}

export const user_info = async () => {
    return await Auth.currentUserInfo()
}

export default {
    inicializar,
    auth_federated_sign_in,
    sign_out,
    user_info
}