/* eslint-disable */
import store from '@/store'

export default {
  name: 'errorpage',
  data: () => {
    return {
      code_error: store.getters.getErrorCode,
      message_error: store.getters.getErrorMsg,
    }
  },

  created() {
    store.commit('clearError')
  },
  computed: {
  },
  methods: {

  }
}
