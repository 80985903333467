<template>
  <div class="form-callcenter">
    <h1>{{ title }}</h1>
    <div class="mb-3 input-group text-start ">
      <label for="callcenterSelect" class="col-sm-5 text-success fw-bold form-label">Tipo de archivo</label>
      <select id="callcenterSelect" class="col-sm-7 form-select" aria-label="callcenterSelect" v-model="type">
        <option selected></option>
        <option value="1">EMITIDA</option>
        <option value="2">RECIBIDA</option>
      </select>
    </div>

    <div class="text-start mb-3">
      <label for="callcenterfile" class="col-sm-5 text-success fw-bold form-label">Seleccionar archivo</label>
      <input class="form-control" ref="fileupload" type="file" id="callcenterfile" accept=".csv"  @change="handleFileUpload( $event )">
    </div>
  </div>
    <div class="">
  <button type="button" @click="save" class="btn btn-outline-primary">Procesar archivo</button>
</div>

</template>
  

<style src="./formcallcenter.css" scoped></style>
<script src="./formcallcenter.view.js"></script>

 