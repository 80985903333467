<template>
  <div :class="{ 'is-super-admin': is_super_admin ? 'admin': is_admin }">
  <!-- <div> -->
    <NavigationBar />
    <div class="container-fluid">
      <div class="row flex-nowrap">
        <SideBar v-if="is_logged" />
        <div id="app" class="col py-3 justify-content-center align-items-center text-center">
          <router-view />
        </div>
      </div>
    </div>
    <FixedTimerUser v-if="is_logged"></FixedTimerUser>
  </div>
</template>
<style src="./app.css"></style>
<script src="./app.view.js"></script>
