// import $ from 'jquery'
import store from '@/store'
import apiAIT from '@/common/ateneaInvoiceToolsApi'

export default {
    props: { fixed: Boolean },

    data: () => {
        return {
            total_seconds: 0,
            running: false,
            hours: "00",
            minutes: "00",
            seconds: "00",
            show_seconds: false,
            show_pause_button: false,
            id_record: null,
            interval: null
        }
    }, computed: {
        tt_seconds() {
            return store.getters.getSeconds
        },
        tt_running() {
            return store.getters.getRunning
        },
        tt_project() {
            return store.getters.getProject
        },
        tt_department() {
            return store.getters.getDepartment
        },
        tt_user() {
            return store.getters.getUser
        },
        has_deparment_and_project() {
            if (store.getters.getProject != undefined && store.getters.getProject != null &&
                store.getters.getDepartment != undefined && store.getters.getDepartment != null
            ) { return true }
            return false
        }
    },
    methods: {
        format_ddbb(date) {
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let hours = date.getHours();
            let minutes = date.getMinutes()
            let seconds = date.getSeconds()

            return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        },
        async start() {
            if (!this.has_deparment_and_project) {
                alert("Debe seleccionar departamento y proyecto")
                return
            }
            //Insert en tabla de timer
            let fecha_inicio = new Date()
            let deparment = this.tt_department
            let project = this.tt_project
            let params = {
                user_id: this.tt_user.user.id,
                department_id: deparment.id,
                project_id: project.id,
                start_date: this.format_ddbb(fecha_inicio),
                notes: null
            }

            console.log("fecha_inicio", fecha_inicio)
            console.log("deparment", deparment)
            console.log("project", project)
            console.log("params", params)
            //
            const loader = this.$loading.show({
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: false,
                onCancel: this.onCancel,
                loader: 'dots'
            });
            await apiAIT.postTimerRecord(params).then((r) => {
                store.commit('setRunning', true)
                store.commit('setTimerDate', fecha_inicio)
                console.log("Response", r)
                if ("id" in r) {
                    this.id_record = r.id
                    store.commit('setTimerId', this.id_record)
                } else {
                    alert("Hubo un error al recibir la respuesta no se encuentra el id")
                }
            }).catch((err) => {
                console.error(err)
                alert("Se produjo un error")
            }).finally(() => {
                loader.hide()
            })
        },
        pause() {
            if (!this.has_deparment_and_project) {
                alert("Debe seleccionar departamento y proyecto")
                return
            }
            console.log("PAUSE")
            store.commit('setRunning', false)
            alert("No")
        },
        async stop() {
            if (!this.has_deparment_and_project) {
                alert("Debe seleccionar departamento y proyecto")
                return
            }
            if (this.id_record == null || this.id_record == undefined) {
                this.id_record = store.getters.getTimerId
            }
            if (!this.id_record) {
                alert("No hay registro iniciado")
                return
            }
            console.log("STOP")
            console.log("Segundos", this.total_seconds)
            let fecha_fin = new Date()
            let deparment = this.tt_department
            let project = this.tt_project
            console.log("fecha_fin", fecha_fin)
            console.log("deparment", deparment)
            console.log("project", project)
            let params = {
                seconds: this.total_seconds + 1,
                end_date: this.format_ddbb(fecha_fin),
                notes: null
            }
            console.log("params", params)
            const loader = this.$loading.show({
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: false,
                onCancel: this.onCancel,
                loader: 'dots'
            });
            await apiAIT.putTimerRecord(this.id_record, params).then((r) => {
                console.log("Response", r)
                this.hours = "00"
                this.minutes = "00"
                this.seconds = "00"
                store.commit('clearTimer')
                this.id_record = null
            }).catch((err) => {
                console.error(err)
                alert("Se produjo un error")
            }).finally(() => {
                loader.hide()
            })
        },
    },
    created() {
        console.log("fixed", this.fixed)
        if (this.fixed) {
            let date_start = store.getters.getTimerDate
            let department = store.getters.tt_department
            let project = store.getters.tt_project
            console.log("department", department)
            console.log("project", project)
            console.log("date_start", this.date_start)

            console.log(typeof (date_start))
            if (date_start != null && date_start != undefined) {
                date_start = new Date(date_start)
                let seconds = parseInt(((new Date()).getTime() - date_start.getTime()) / 1000)
                console.log("Fecha guardada", date_start)
                console.log("Segundos", seconds)
                if (this.tt_seconds != seconds) {
                    store.commit('setSeconds', seconds)
                }
            }
        }
        this.seconds = pad(this.tt_seconds % 60);
        this.minutes = pad(parseInt(this.tt_seconds / 60, 10) % 60);
        this.hours = pad(parseInt(this.tt_seconds / 3600, 10));
        function pad(val) { return val > 9 ? val : "0" + val; }
        this.interval = setInterval(function () {
            this.total_seconds = this.tt_seconds
            if (this.total_seconds == null) {
                this.seconds = "00"
                this.minutes = "00"
                this.hours = "00"
                this.total_seconds = 0
            }
            this.running = this.tt_running
            if (this.running) {
                if (this.fixed) {
                    this.seconds = pad(++this.total_seconds % 60);
                    this.minutes = pad(parseInt(this.total_seconds / 60, 10) % 60);
                    this.hours = pad(parseInt(this.total_seconds / 3600, 10));
                    store.commit('setSeconds', this.total_seconds)
                } else {
                    this.seconds = pad(this.total_seconds % 60);
                    this.minutes = pad(parseInt(this.total_seconds / 60, 10) % 60);
                    this.hours = pad(parseInt(this.total_seconds / 3600, 10));
                }
            }
        }.bind(this), 1000);
    },
    destroyed() {
        clearInterval(this.interval)
    }

}