/* eslint-disable */
import amplify from '@/common/amplify'
import store from '@/store'
export default {
  name: 'HomePage',
  data: () => {
    return {
      code: null,
      loader: null,
    }
  },

  created() {
    console.log("Home: Created")
  },
  computed: {
    has_code() {
      this.code = this.$route.query.code
      console.log(this.code)
      if (this.code != null && this.code != undefined) {
        this.loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          loader: 'dots',
        });
        return "Iniciando sesión"
      }
      return "F"
    },
    is_logged() {
      if (this.loader != null) {
        this.loader.hide()
      }
      return store.getters.isLoggedIn
    },
    msg() {
      return store.getters.getUserName
    },
  },
  methods: {
  }
}
