/* eslint-disable */
import store from '@/store'
import apiAIT from '@/common/ateneaInvoiceToolsApi'

export default {
  name: 'form-invoice',
  props: { filetype: String },
  data: () => {
    return {
      user: store.getters.getUser,
      title: null,
    }
  },

  created() {
    if (this.filetype == 'FRATERNIDAD') {
      this.title = "Fraternidad"
    } else if (this.filetype == 'UNIVERSAL') {
      this.title = "Universal"
    } else if (this.filetype == 'ARCHIVOSAGE') {
      this.title = "Sage"
    }
  },
  computed: {
  },
  methods: {

  }
}
