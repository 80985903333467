<template>
  <div class="form-callcenter">
    <h1>{{ title }}</h1>
    <button class="collapse-icon btn btn-link"  
    type="button" data-bs-toggle="collapse" data-bs-target="#info" aria-expanded="false" aria-controls="info">
      <i class="fa-solid fa-circle-info"></i>
    </button>
    <div class="mb-3 input-group text-start collapse" id="info">
      En esta página puede subir un fichero excel con los pagos a realizar (VECI) en Sage. Pasos:

      <ol class="list-group list-group-flush">
        <li class="list-group-item"> <strong>1.</strong> Seleccione el fichero</li>
        <li class="list-group-item"> <strong>2.</strong> Haga click en "Procesar archivo"</li>
      </ol>
      <ul>
        <li>
          Si marca la casilla "Enviar a Sage", el documento se enviará a una cola para su posterior envío a Sage.
        </li>
        <li>
          Si marca la casilla "Generar informe", se generará un fichero excel con: el número de factura, importe cobrado,
          importe de factura, importe de comisión, base imponible y porcentaje de comisión.
        </li>
        <li>
          En caso de que falte alguna factura en la base de datos, suba el documento que contiene dicha factura en <label class="fw-bold text-success">Pagos Sage: Actualizar datos</label>
        </li>

      </ul>
    </div>

    <div class="text-start mb-3">
      <label for="callcenterfile" class="col-sm-5 text-success fw-bold form-label">Seleccionar archivo</label>
      <input class="form-control" type="file" id="callcenterfile">
    </div>
  </div>
  <div class="form-check text-start">
    <input class="form-check-input" type="checkbox" value="" id="sendsage">
    <label class="form-check-label" for="sendsage">
      Enviar a Sage
    </label>
  </div>
  <div class="form-check text-start">
    <input class="form-check-input" type="checkbox" value="" id="report" checked>
    <label class="form-check-label" for="report">
      Generar informe
    </label>
  </div>
  <div class="">
    <button type="button" @click="save" class="btn btn-outline-primary">Procesar archivo</button>
  </div>
</template>
  

<style src="./formsagepayments.css" scoped></style>
<script src="./formsagepayments.view.js"></script>

 