

// Añadir token 
export const add_key = (config) => {
  const key = process.env.VUE_APP_API_KEY
  if (key) {
    config.headers.Authorization = `Bearer ${key}`;
  }

  return config;
}

export const request_interceptors = [
  add_key,
]

export const error_interceptors = [
]