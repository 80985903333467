export default {
    props: { user: Object },
    computed: {
        get_active() {
            if (this.user != undefined && this.user != null) {
                if (this.user.user.active) {
                    return "Sí"
                }
            }
            return "No"
        },
        get_created() {
            if (this.user != undefined && this.user != null) {
                let date = new Date(this.user.user.created_at)
                return this.parse_date(date)
            }
            return ""
        },
        get_updated() {
            if (this.user != undefined && this.user != null) {
                let date = new Date(this.user.user.updated_at)
                return this.parse_date(date)
            }
            return ""
        }
    }, 
    methods:{
        parse_date(date){
            return `${date.getDay().toString().padStart(2, '0')}-${(date.getMonth()+1).toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
        }
    }

}