<template>
  <div class="home">
    <div class="alert alert-warning" role="alert">
      <h1 v-if="this.code_error">{{ this.code_error }}</h1>
      <p v-if="this.message_error">{{ this.message_error }}</p>
    </div>

  </div>
</template>
  

<style src="./errorpage.css" scoped></style>
<script src="./errorpage.view.js"></script>

 