export default {
    props: { array_minutes_hours: Object },
    methods: {},
    computed: {
        rnd_color() {
            let number = Math.floor(Math.random() * 4) + 1
            switch (number) {
                case 1:
                    return "color-red"
                case 2:
                    return "color-blue"
                case 3:
                    return "color-green"
                case 4:
                    return "color-orange"
                case 5:
                    return "color-yellow"
            }
        }
    }

}