/* eslint-disable */
import store from '@/store'
import apiAIT from '@/common/ateneaInvoiceToolsApi'

export default {
  name: 'form-callcenter',
  data: () => {
    return {
      user: store.getters.getUser,
      title: 'Callcenter',
      file: null,
      type: null,
    }
  },

  created() {
  },
  computed: {
  },
  methods: {
    handleFileUpload(e) {
      this.file = e.target.files[0];
    },
    save() {
      console.log("TIpo:", this.type, "file", this.file)
      if (this.type == null || this.type == undefined || this.type.length == 0) {
        alert("Debe indicar el tipo de archivo de llamada.")
        return
      }
      if (this.file == null) {
        alert("Debe seleccionar un archivo")
        return
      }
      if (this.file.type !== "text/csv") {
        alert("El archivo debe ser CSV")
        return
      }

      let formData = new FormData()
      formData.append('method', 'POST')
      formData.append('file', this.file)
      apiAIT.postFileCallcenter(formData, this.type).then(
        (r)=>{
          console.log(r)
          this.file = null
          this.type = null
          this.$refs.fileupload.value=null;

        }
      ).catch(
        (err)=>{
          console.error(">",err)
        }
      )
    }
  }
}
