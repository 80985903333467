<template>
    <div v-if="!timer_is_running" class="card card-select mb-3">
        <div class="card-body">
            <div class="row mb-3 card-text">
                <div class="col-sm text-md-start">
                    <label class="col-sm-3 lbl-timerview text-success fw-bold">Seleccionar departamento:</label>
                    <VueSelect class="form-control-2 col-sm-9" v-model="selected_department"
                        :options="departments.map(c => { return { label: c.name, ...c } })">
                        <template #search="{ attributes, events }">
                            <input class="vs__search" :required="!selected_department" v-bind="attributes" v-on="events" />
                        </template>
                    </VueSelect>
                </div>
            </div>
            <div class="row mb-3 card-text">
                <div class="col-sm text-md-start">
                    <label class="col-sm-3 lbl-timerview text-success fw-bold">Seleccionar proyecto:</label>
                    <VueSelect class="form-control-2 col-sm-9" v-model="selected_project"
                        :options="projects.map(c => { return { label: c.name, ...c } })">
                        <template #search="{ attributes, events }">
                            <input class="vs__search" :required="!selected_project" v-bind="attributes" v-on="events" />
                        </template>
                    </VueSelect>
                </div>
            </div>

            <div class="row mb-0 card-text">
                <div class="col-sm text-md-center">
                    <button :disabled="!selected_department || !selected_project" type="button" class="btn btn-warning"
                        @click="select_department_project">Seleccionar departamento y proyecto</button>
                </div>
                <div v-if="this.selected" class="col-sm text-md-center">
                    <button type="button" class="btn btn-danger" @click="cancelar_seleccion">Cancelar selección</button>
                </div>
            </div>
            <div v-if="!selected_department || !selected_project" class="row card-text mt-3 ">
                <div class="col-sm text-md-center">
                    <div class="alert alert-warning mb-0" role="alert">
                        Selecciona departamento y proyecto
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="selected" class="card card-alert-timer mb-3">
        <div class="alert alert-success mb-0" role="alert">
            Departamento seleccionado: {{ selected_department.name }} - Proyecto: {{ selected_project.name }}
        </div>
    </div>

    <div class="card card-timer">
        <TimerUser :fixed="false"></TimerUser>
    </div>
</template>

<style src="./timerview.css" scoped></style>
<script src="./timerview.view.js"></script>