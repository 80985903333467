<template>
    <div class="invoice">
        <h1>Facturación</h1>
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="card p-3">
                        <div class="btn-group-vertical">
                            <button type="button" @click="select('callcenter')" class="btn btn-outline-primary"><i class="fa-solid fa-phone fa-shake"></i> Procesar llamadas callcenter</button>
                            <button type="button" @click="select('fraternidad')" class="btn btn-outline-secondary">Procesar Archivo Fraternidad</button>
                            <button type="button" @click="select('universal')" class="btn btn-outline-success">Procesar Archivo Universal</button>
                            <button type="button" @click="select('archivosage')" class="btn btn-outline-danger">Procesar Archivo Sage</button>
                            <button type="button" @click="select('facturacionsage')" class="btn btn-outline-warning">Facturación Sage</button>
                            <button type="button" @click="select('pagosage')" class="btn btn-outline-info">Pagos Sage</button>
                            <button type="button" @click="select('pagosageupdate')" class="btn btn-outline-info">Pagos Sage: Actualizar datos</button>
                            <button type="button" @click="select('raas')" class="btn btn-outline-dark">Subida RAAS</button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div v-if="item_selected" class="card p-3">
                        <FormCallcenter v-if="item_selected=='CALLCENTER'"></FormCallcenter>
                        <FormInvoiceFUS v-else-if="item_selected=='FRATERNIDAD'" :filetype="item_selected"></FormInvoiceFUS>
                        <FormInvoiceFUS v-else-if="item_selected=='UNIVERSAL'" :filetype="item_selected"></FormInvoiceFUS>
                        <FormInvoiceFUS v-else-if="item_selected=='ARCHIVOSAGE'" :filetype="item_selected"></FormInvoiceFUS>
                        <FormInvoiceSage v-else-if="item_selected=='FACTURACIONSAGE'"></FormInvoiceSage>
                        <FormSagepayments v-else-if="item_selected=='PAGOSAGE'"></FormSagepayments>
                        <FormSagepaymentsUpdate v-else-if="item_selected=='PAGOSAGEUPDATE'"></FormSagepaymentsUpdate>
                        <FormRaas v-else-if="item_selected=='RAAS'"></FormRaas>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  

<style src="./invoiceprocess.css" scoped></style>
<script src="./invoiceprocess.view.js"></script>

 