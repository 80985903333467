<template>
  <div class="form-invoice-sage">
    <h1>{{ title }}</h1>
    <button class="collapse-icon btn btn-link"  
    type="button" data-bs-toggle="collapse" data-bs-target="#info" aria-expanded="false" aria-controls="info">
      <i class="fa-solid fa-circle-info"></i>
    </button>
    <div class="col text-start mb-2 collapse" id="info">
      <div class="">
        Suba un fichero excel con los datos de los servicios a facturar o de los abonos/rectificaciones a crear (VECI).
        Pasos:

        <ol class="list-group list-group-flush">
          <li class="list-group-item"> <strong>1.</strong> Seleccione el fichero</li>
          <li class="list-group-item"> <strong>2.</strong> Haga click en "Procesar archivo"</li>
        </ol>
      </div>

      Una vez completado el proceso se le mostrará un resumen del mismo, indicando si ha finalizado
      correctamente o
      no.
    </div>
    <div class="accordion" id="accordion-invoices-sage">
      <div class="accordion-item">
        <h2 class="accordion-header" id="facturas">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#facturasbody"
            aria-expanded="true" aria-controls="facturas">
            FACTURAS
          </button>
        </h2>
        <div id="facturasbody" class="accordion-collapse collapse" aria-labelledby="facturas"
          data-bs-parent="#accordion-invoices-sages">
          <div class="accordion-body">
            <div class="text-start mb-3">
              <label for="invoicesage" class="col-sm-5 text-success fw-bold form-label">Seleccionar archivo</label>
              <input class="form-control" type="file" id="invoicesage">
            </div>
          </div>
          <div class="">
            <button type="button" @click="saveInvoices" class="btn btn-outline-primary mb-3">Procesar archivo</button>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion" id="accordion-rectification-credits">
      <div class="accordion-item">
        <h2 class="accordion-header" id="abonos-rectificaciones">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#abonosbody"
            aria-expanded="true" aria-controls="abonos-rectificacione">
            ABONOS/RECTIFICACIONES
          </button>
        </h2>
        <div id="abonosbody" class="accordion-collapse collapse" aria-labelledby="abonos-rectificaciones"
          data-bs-parent="#accordion-rectification-creditss">
          <div class="accordion-body">
            <div class="text-start mb-3">
              <label for="rectification-credits-sage" class="col-sm-5 text-success fw-bold form-label">Seleccionar
                archivo</label>
              <input class="form-control" type="file" id="rectification-credits-sage">
            </div>
          </div>
          <div class="">
            <button type="button" @click="saveRectification" class="btn btn-outline-primary mb-3">Procesar
              archivo</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
  

<style src="./forminvoicesage.css" scoped></style>
<script src="./forminvoicesage.view.js"></script>

 