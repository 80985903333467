/* eslint-disable */
import store from '@/store'
export default {
  name: 'HomePage',
  data: () => {
    return {
     item_selected:null
    }
  },

  created() {
  },
  computed: {
   
  },
  methods: {
    select(o){
      if(o!=null&&o!=undefined){
        this.item_selected = o.toUpperCase()
        console.log("Seleccionado ", this.item_selected)
      }
    }
  }
}
