import axios from 'axios';
import { request_interceptors, error_interceptors } from './interceptors';

const api = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    'Accept': 'application/json'
  }
});

request_interceptors.forEach(interceptor => {
  api.interceptors.request.use(interceptor);
});

error_interceptors.forEach(interceptor => {
  api.interceptors.response.use(null, interceptor);
});

export const postUser = async (user) => {
  const response = await api.post('/users', user);
  return response.data.result;
};

export const getUser = async () => {
  const response = await api.get('/users');
  return response.data.result;
};

export const getUserDepartmentsProjects = async (user_id) => {
  const response = await api.get(`/user-departments-projects/${user_id}`);
  return response.data.result;
};

export const postTimeTracking = async (timetracking) => {
  const response = await api.post('/time-tracking', timetracking);
  return response.data.result;
};
export const getTimeTracking = async (user_id, start_date, end_date = null) => {
  if (end_date == null || end_date == undefined) {
    end_date = start_date
  }
  let params = `user_id=${user_id}&start_date=${start_date}&end_date=${end_date}`
  const response = await api.get(`/time-tracking?${params}`);
  return response.data.result;
};

export const postTimerRecord = async (params) => {
  const response = await api.post('/timer-records', params);
  return response.data.result;
};

export const putTimerRecord = async (tr_id, params) => {
  const response = await api.put(`/timer-records/${tr_id}`, params);
  return response.data.result;
};

export const postDepartments = async (params) => {
  const response = await api.post(`/admin/departments`, params);
  return response.data.result;
};
export const postProjects = async (params) => {
  const response = await api.post(`/admin/projects`, params);
  return response.data.result;
};
export const getProjects = async () => {
  const response = await api.get(`/admin/departments-projects`);
  return response.data.result;
};

export const getDepartments = async () => {
  const response = await api.get(`/admin/departments`);
  return response.data.result;
};

export const getDepartmentsProjectsSelect = async () => {
  const response = await api.get(`/admin/departments-projects-v2`);
  return response.data.result;
};

export const postAssignProject = async (params) => {
  const response = await api.post(`/user-departments-projects/projects`, params);
  return response.data.result;
};

export const postAssignDepartment = async (params) => {
  const response = await api.post(`/user-departments-projects/departments`, params);
  return response.data.result;
};

export const postFileCallcenter = async(params, file_type) =>{
  const response = await api.post(`/invoices/callcenter?file_type=${file_type}`, params, {headers: {
    'Content-Type': 'multipart/form-data',
  }});
  
  return response.data.result;
}


export default {
  postUser,
  getUser,
  getUserDepartmentsProjects,
  postTimeTracking,
  getTimeTracking,
  putTimerRecord,
  postTimerRecord,
  getProjects,
  postDepartments,
  postProjects,
  getDepartments,
  getDepartmentsProjectsSelect,
  postAssignProject,
  postAssignDepartment,
  postFileCallcenter,
}