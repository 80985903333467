import { createApp } from 'vue'
import App from '@/views/app/App.vue';
import router from './router';
import store from './store';
import { LoadingPlugin } from 'vue-loading-overlay';
import vSelect from 'vue-select';
import NavigationBar from './components/navigation/NavigationBar';
import SideBar from './components/sidebar/SideBar';
import UserProfile from './components/userprofile/UserProfile'
import TableTrackings from './components/tabletrackings/TableTrackings'
import ResumeTrackings from './components/resumetrackings/ResumeTrackings'
import TimerUser from './components/timeruser/TimerUser'
import FixedTimerUser from './components/fixedtimeruser/FixedTimerUser.vue'
import AdminDepartments from './components/admindepartments/AdminDepartments'
import AdminProjects from './components/adminprojects/AdminProjects'
import AdminUsers from './components/adminusers/AdminUsers'
import TableDepartments from './components/tabledepartments/TableDepartments'
import TableDepartmentsProjectsUser from './components/tabledepartmentsprojectsuser/TableDepartmentsProjectsUser'
import TableProjects from './components/tableprojects/TableProjects'
import TableUsers from './components/tableusers/TableUsers'
import FormCallcenter from './components/formcallcenter/FormCallcenter'
import FormInvoiceFUS from './components/forminvoiceFUS/FormInvoiceFUS'
import FormInvoiceSage from './components/forminvoicesage/FormInvoiceSage'
import FormRaas from './components/formraas/FormRaas'
import FormSagepayments from './components/formsagepayments/FormSagepayments'
import FormSagepaymentsUpdate from './components/formsagepaymentsupdate/FormSagepaymentsUpdate'
import { vMaska } from "maska"
import 'vue-loading-overlay/dist/css/index.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import '@fortawesome/fontawesome-free/js/all.js';
import 'vue-select/dist/vue-select.css';
import "@fontsource/share-tech-mono";

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresError)) {
        if (store.getters.has_errors) {
            next()
        } else {
            next({ name: 'Home page' })
        }

    }
    else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.isLoggedIn) {
            const originalRoute = to.fullPath;
            next({ name: 'Home page', query: { redirect: originalRoute } });
        } else {
            if (to.matched.some(record => record.meta.requiresSuperAdmin)) {
                if (store.getters.getAdmin && store.getters.getAdmin.superadmin) {
                    next()
                } else {
                    next({ name: 'Home page' })
                }
            } else if (to.matched.some(record => record.meta.requiresAdmin)) {
                if (store.getters.getAdmin) {
                    next()
                } else {
                    next({ name: 'Home page' })
                }
            } else {
                next()
            }

        }
    } else if (to.matched.some(record => record.meta.hideForAuth)) {
        if (store.getters.isLoggedIn) {
            next({ name: 'Home page' })

        } else {
            next()
        }
    } else {

        next()
    }
}
)

const app = createApp(App)


app.component('v-select', vSelect)

app.component('NavigationBar', NavigationBar)
app.component('SideBar', SideBar)
app.component('UserProfile', UserProfile)
app.component('TableTrackings', TableTrackings)
app.component('ResumeTrackings', ResumeTrackings)
app.component('TimerUser', TimerUser)
app.component('FixedTimerUser', FixedTimerUser)
app.component('AdminDepartments', AdminDepartments)
app.component('AdminProjects', AdminProjects)
app.component('AdminUsers', AdminUsers)
app.component('TableDepartments', TableDepartments)
app.component('TableProjects', TableProjects)
app.component('TableDepartmentsProjectsUser', TableDepartmentsProjectsUser)
app.component('TableUsers', TableUsers)
app.component('FormCallcenter', FormCallcenter)
app.component('FormInvoiceFUS', FormInvoiceFUS)
app.component('FormInvoiceSage', FormInvoiceSage)
app.component('FormRaas', FormRaas)
app.component('FormSagepayments', FormSagepayments)
app.component('FormSagepaymentsUpdate', FormSagepaymentsUpdate)
app.use(LoadingPlugin)
app.use(store)
app.use(router)
app.directive("maska", vMaska)
app.mount('#app')

