import amplify from '@/common/amplify'
import store from '@/store'
// import $ from 'jquery'

export default {
  name: "App",
  data: () => {
    return {
        loader : null
    }
  },
  created(){
    console.log("app Created")
    amplify.inicializar()
  },
  computed: {
    is_logged() {
      return store.getters.isLoggedIn
    },
    is_admin() {
      if (store.getters.getAdmin) {
        return true
      }
      return false
    },
    is_super_admin() {
      if (store.getters.getAdmin != null && store.getters.getAdmin != undefined) {
        return store.getters.getAdmin.superadmin
      }
      return false
    }

  },
  methods: {
  },
}
