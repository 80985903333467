
export const conf = {
  identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
  region: process.env.VUE_APP_REGION,
  userPoolId: process.env.VUE_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.VUE_APP_CLIENT_ID,
  mandatorySignIn: false,
  signUpVerificationMethod: 'code', // 'code' | 'link'
}
export const auth = {
  domain: process.env.VUE_APP_COGNITO_DOMAIN,
  scope: [
    'phone',
    'email',
    'openid',
    'aws.cognito.signin.user.admin'
  ],
  redirectSignIn: process.env.VUE_APP_COGNITO_REDIRECT_SI,
  redirectSignOut: process.env.VUE_APP_COGNITO_REDIRECT_SO,
  responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code


}


export default {
  auth,
  conf
}