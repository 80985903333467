export default {
    props: {
        users: Object,
        collapse: Boolean
    },
    computed: {

    },
    methods: {
        setCurrentUser(u) {
            this.$emit('set-current-user', u)
        }
    }

}