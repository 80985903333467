import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    cognitoUser: null,
    user: null,
    user_name: null,
    token: null,
    user_email: null,
    user_id: null,
    error_code: null,
    error_msg: null,
    timer_seconds: null,
    timer_running: false,
    timer_department: null,
    timer_project: null,
    timer_date:null,
    timer_id:null,
    admin : null,
    timer_current_date:null
  },
  plugins: [createPersistedState()],
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setUserName(state, user_name) {
      state.user_name = user_name;
    },
    setCognitoUser(state, user) {
      state.cognitoUser = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setUserEmail(state, user_email) {
      state.user_email = user_email;
    },
    setErrorMsg(state, error_msg) {
      state.error_msg = error_msg;
    },
    setErrorCode(state, error_code) {
      state.error_code = error_code;
    },
    setSeconds(state, seconds) {
      state.timer_seconds = seconds;
    },
    setRunning(state, running) {
      state.timer_running = running;
    },
    setDepartment(state, timer_department) {
      state.timer_department = timer_department;
    },
    setProject(state, timer_project) {
      state.timer_project = timer_project;
    },
    setTimerDate(state, timer_date) {
      state.timer_date = timer_date;
    },
    setTimerId(state, timer_id) {
      state.timer_id = timer_id;
    },
    setTimerCurrentDate(state, timer_current_date) {
      state.timer_current_date = timer_current_date;
    },
    setAdmin(state, admin) {
      state.admin = admin;
    },
    logout(state) {
      state.user = null;
      state.user_name = null;
      state.cognitoUser = null;
      state.token = null;
      state.user_id = null;
      state.user_email = null;
      state.admin = null;
    },
    clearError(state) {
      state.error_code = null;
      state.error_msg = null;
    },
    clearTimer(state){
      //en el login chedck
      state.timer_current_date = null;
      state.timer_seconds = null;
      state.timer_running = false;
      state.timer_department = null;
      state.timer_project = null;
      state.timer_date = null;
      state.timer_id = null;
    }
  },
  actions: {},
  getters: {
    isLoggedIn(state) {
      return !!state.token;
    },
    getCognitoUser(state) {
      return state.cognitoUser;
    },
    getUser(state) {
      return state.user;
    },
    getUserName(state) {
      return state.user_name;
    },
    getToken(state) {
      return state.token;
    },
    getUserEmail(state) {
      return state.user_email;
    },
    has_errors(state) {
      return state.error_code != null || state.error_msg != null;
    },
    getErrorCode(state) {
      return state.error_code
    },
    getErrorMsg(state) {
      return state.error_msg
    },
    getSeconds(state) {
      return state.timer_seconds
    },
    getRunning(state) {
      return state.timer_running
    },
    getDepartment(state) {
      return state.timer_department
    },
    getProject(state) {
      return state.timer_project
    },
    getTimerDate(state) {
      return state.timer_date
    },
    getTimerId(state) {
      return state.timer_id
    },
    getAdmin(state) {
      return state.admin
    },
    getTimerCurrentDate(state) {
      return state.timer_current_date
    },
  },
});
