<template>
  <TableProjects :projects="projects"></TableProjects>
  <div class="card mt-3 table-projects p-5">
    <div class="input-group mb-3">
      <label class="col-sm-3 lbl-timerview text-success fw-bold">Seleccionar departamento:</label>
      <VueSelect class="form-control-2 col-sm-9" v-model="selected_department"
        :options="departments.map(c => { return { label: c.name, ...c } })">
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!selected_department" v-bind="attributes" v-on="events" />
        </template>
      </VueSelect>
    </div>

    <div class="input-group">
      <input v-model="project_name" type="text" class="form-control" placeholder="" aria-label="Nombre del proyecto">
      <button class="btn btn-outline-secondary" type="button" @click="save">Guardar proyecto</button>
    </div>
  </div>
</template>
  

<style src="./adminprojects.css" scoped></style>
<script src="./adminprojects.view.js"></script>

 