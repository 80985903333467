/* eslint-disable */
import store from '@/store'
import apiAIT from '@/common/ateneaInvoiceToolsApi'
import VueSelect from 'vue-select'

export default {
  name: 'admin-user',
  components: {
    VueSelect,
  },
  data: () => {
    return {
      users: [],
      selected_user: null,
      user_departments_projects: [],
      departments: [],
      projects: [],
      selected_project: null,
      selected_department: null,
    }
  },

  created() {
  },
  async mounted() {
    await this.get_users()
  },
  computed: {
  },
  methods: {
    async get_user_d_p() {
      console.log(this.selected_user)
      if (this.selected_user != null && this.selected_user != undefined) {
        let array_dp = await apiAIT.getUserDepartmentsProjects(this.selected_user.user.id)
        array_dp.forEach(element => {
          console.log(">>", element)
          element.projects.forEach(project => {
            console.log(">>", project)
          });
        });
        return array_dp
      }
      return []
    },
    async get_users() {
      const loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        onCancel: this.onCancel,
        loader: 'dots'
      });
      await apiAIT.getUser().then((r) => {
        console.log(r)
        if (r) {
          this.users = r
        }
      }).catch((err) => {
        console.error("get_users", err)
      }).finally(() => {
        loader.hide()
      })

    },
    async departmentAssing() {
      if (!this.selected_user) {
        alert("Debes seleccionar un usuario para asignarle el departamento")
        return
      }
      if (!this.selected_department) {
        alert("Debes seleccionar un departamento para asignarselo al usuario")
        return
      }
      let params = { "user_id": this.selected_user.user.id, "department_id": this.selected_department.id }

      console.log("Selected department", this.selected_department)
      console.log("params", params)
      const loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        onCancel: this.onCancel,
        loader: 'dots'
      });
      await apiAIT.postAssignDepartment(params).then((r) => {
        console.log("departmentAssing", r)
      }).catch((err) => {
        console.error("departmentAssing", err)
      }).finally(() => {
        loader.hide()
      })
      await this.reload()

    },
    async projectAssing() {
      if (!this.selected_user) {
        alert("Debes seleccionar un usuario para asignarle el proyecto")
        return
      }
      if (!this.selected_project) {
        alert("Debes seleccionar un proyecto para asignarselo al usuario")
        return
      }
      let params = { "user_id": this.selected_user.user.id, "project_id": this.selected_project.id, "department_id": this.selected_department.id }

      console.log("Selected project", this.selected_project)
      console.log("params", params)
      const loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        onCancel: this.onCancel,
        loader: 'dots'
      });
      await apiAIT.postAssignProject(params).then((r) => {
        console.log("projectAssing",r)
      }).catch((err) => {
        console.error("projectAssing", err)
      }).finally(() => {
        loader.hide()
      })
      await this.reload()
    },
    async setCurrentUser(user) {
      console.log("RECIBIDO", user)
      if (user) {
        this.selected_user = user
        this.user_departments_projects = await this.get_user_d_p()
        await this.getDepartmentsProjectsSelect()
      } else {
        this.user_departments_projects = []

      }
    },
    async getDepartmentsProjectsSelect() {
      // getDepartmentsProjectsSelect
      const loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        onCancel: this.onCancel,
        loader: 'dots'
      });
      await apiAIT.getDepartmentsProjectsSelect().then((r) => {
        console.log(r)
        if (r) {
          this.departments = r
        }
      }).catch((err) => {
        console.error("get_users", err)
      }).finally(() => {
        loader.hide()
      })
    },
    async reload() {
      this.user_departments_projects = await this.get_user_d_p()
      await this.get_users()
      let user_id = this.selected_user.user.id
      this.selected_user = this.users.find((element) => element.user.id == user_id);
    }
  },
  watch: {
    selected_department() {
      if (this.selected_department == null && this.selected_department == undefined) {
        this.projects = []
        this.selected_project = null
        return
      }
      this.projects = []
      this.projects = this.selected_department.projects

    },
    selected_project() {
      console.log("selected_project", this.selected_project)
    }
  }
}
