export default {
    // props: { user: Object },
    data: () => {
        return {
            display_timer: true
        }
    },
    created(){
        console.log("Created fixed")
    },
    computed: {
        
    },
    methods: {
        toggleTimer() {
            this.display_timer = !this.display_timer;
        }
    }

}