<template>
  <div class="admin-departments">
    <TableDepartments :departments="departments"></TableDepartments>
    <div class="card mt-3 table-deparments p-5">
      <div class="input-group">
        <input v-model="department_name" type="text" class="form-control" placeholder=""
          aria-label="Nombre del departamento">
        <button class="btn btn-outline-secondary" type="button" @click="save">Guardar departamento</button>
      </div>
    </div>

  </div>
</template>
  

<style src="./admindepartments.css" scoped></style>
<script src="./admindepartments.view.js"></script>

 