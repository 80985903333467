<template>
    <div class="side-bar-atenea col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-success">
        <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white vh-header">
            <ul class="nav flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                <li class="">
                    <a :href="'/'" class="nav-link align-middle px-0">
                        <i class="fa-solid fa-house"></i> <span class="ms-1 d-none d-sm-inline">Home</span>
                    </a>
                </li>
                <li class="">
                    <a :href="'/time-tracking'" class="nav-link align-middle px-0">
                        <i class="fa-solid fa-calendar"></i> <span class="ms-1 d-none d-sm-inline">Horas</span>
                    </a>
                </li>
                <li class="">
                    <a :href="'/timer'" class="nav-link align-middle px-0">
                        <i class="fa-solid fa-stopwatch"></i><span class="ms-1 d-none d-sm-inline">Contador</span>
                    </a>
                </li>
                <li class="">
                    <a :href="'/invoices'" class="nav-link align-middle px-0">
                        <i class="fa-regular fa-file"></i> <span
                            class="ms-1 d-none d-sm-inline">Facturación</span>
                    </a>
                </li>
                <div class="admin nav flex-column mb-sm-auto mb-0 align-items-center" v-if="is_admin">
                    <div class="side-bar-label">
                        <p>Admin</p>
                    </div>
                    <li class="">
                        <a :href="'/admin/time-tracking-users'" class="nav-link align-middle px-0">
                            <i class="fa-solid fa-dragon"></i> <span class="ms-1 d-none d-sm-inline">Listado Horas usuarios</span>
                        </a>
                    </li>

                    <li class="">
                        <a :href="'/admin/users'" class="nav-link align-middle px-0">
                            <i class="fa-regular fa-face-smile"></i> <span class="ms-1 d-none d-sm-inline">Gestión de
                                usuarios</span>
                        </a>
                    </li>
                    <li class="">
                        <a :href="'/admin/departments'" class="nav-link align-middle px-0">
                            <i class="fa-solid fa-folder-tree"></i><span
                                class="ms-1 d-none d-sm-inline">Departamentos</span>
                        </a>
                    </li>
                    <li class="">
                        <a :href="'/admin/projects'" class="nav-link align-middle px-0">
                            <i class="fa-solid fa-diagram-project"></i> <span
                                class="ms-1 d-none d-sm-inline">Proyectos</span>
                        </a>
                    </li>
                </div>
            </ul>

            <hr>
            <div class="dropdown pb-4">
            </div>
        </div>
    </div>
</template>

<style src="./sidebar.css" scoped></style>
<script src="./sidebar.view.js"></script>