import store from '@/store'
import apiAIT from '@/common/ateneaInvoiceToolsApi'
import { Auth } from 'aws-amplify';
import router from '@/router'

export default {
    async log_in(username, name, email, token, sub) {
        // console.log("Log-in", username, name, email, token, sub)
        let user = {
            name: name,
            username: username,
            email: email,
            sub: sub
        }

        console.log("Se procede a llamar a la API con el usuario", user)
        apiAIT.postUser(user).then(
            (r) => {
                console.log("Respuesta", r)
                console.log("Guardando info en store")
                store.commit('setToken', token);
                store.commit('setCognitoUser', username);
                store.commit('setUserName', name);
                store.commit('setUserEmail', email);
                store.commit('setUser', r);
                store.commit('setAdmin', r.admin);
                console.log(r.departments)
                if (r.departments.length == 0) {
                    console.log("NO tiene departamentos")
                    store.commit("setErrorCode", 'CONSULTA A ADMIN')
                    store.commit("setErrorMsg", 'No tienes departamentos asignados, consulta con un administrador para que te los asigne.')
                    router.push("/error-auth");
                } else {
                    if (r.record_timer) {
                        let start_date = r.record_timer.start_date
                        if (start_date) {
                            console.log("Hay timer pendiente")
                            store.commit('clearTimer')
                            store.commit('setDepartment', r.record_timer.department)
                            store.commit('setProject', r.record_timer.project)
                            store.commit('setTimerId', r.record_timer.id)
                            store.commit('setTimerDate', start_date)
                            store.commit('setSeconds', 1)
                            store.commit('setRunning', true)
                            console.log("Timer recuperado")
                        }
                    }
                    console.log("Tiene departamentos")
                    router.push("/");
                }

            }
        ).catch((err) => {
            console.log("err", err)

            let msg = ''
            let code = null
            if ('response' in err && 'data' in err.response) {
                msg = err.response.data.message
                code = err.response.data.code

            } else {
                if ('response' in err) {
                    msg = err.response.statusText
                    code = err.response.status
                } else {
                    msg = err.message
                    code = err.code
                }
            }
            console.log("Se produjo un error en el LOGIN => ", msg, code)
            store.commit("setErrorCode", code)
            store.commit("setErrorMsg", msg)
            Auth.signOut()
            store.commit('logout')
            router.push("/error-auth");
        })


    }
}