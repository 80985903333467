<template>
  <div class="admin-users">
    <UserProfile v-if="selected_user" :user=this.selected_user></UserProfile>
    <div class="container">
      <div class="row">
        <TableUsers class="col-sm-6" :users="users" v-on:set-current-user="setCurrentUser"></TableUsers>
        <TableDepartmentsProjectsUser class="col-sm-6" :departments_projects="user_departments_projects">
        </TableDepartmentsProjectsUser>
      </div>
    </div>
    <div class="card mt-3 table-projects p-5">

      <div class="input-group mb-3">
        <label class="col-sm-3 lbl-timerview text-success fw-bold">Seleccionar departamento:</label>
        <VueSelect class="form-control-2 col-sm-6" v-model="selected_department"
          :options="departments.map(c => { return { label: c.name, ...c } })">
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!selected_department" v-bind="attributes" v-on="events" />
          </template>
        </VueSelect>
        <button class="btn btn-outline-secondary col-sm-3" type="button" @click="departmentAssing">Asignar departamento</button>

      </div>
      <div class="input-group mb-3">
        <label class="col-sm-3 lbl-timerview text-success fw-bold">Seleccionar proyecto:</label>
        <VueSelect class="form-control-2 col-sm-6" v-model="selected_project"
          :options="projects.map(c => { return { label: c.name, ...c } })">
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!selected_project" v-bind="attributes" v-on="events" />
          </template>
        </VueSelect>
        <button class="btn btn-outline-secondary col-sm-3" type="button" @click="projectAssing">Asignar proyecto</button>
      </div>
    </div>
  </div>
</template>
  

<style src="./adminusers.css" scoped></style>
<script src="./adminusers.view.js"></script>

 