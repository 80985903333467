/* eslint-disable */
import store from '@/store'
import apiAIT from '@/common/ateneaInvoiceToolsApi'

export default {
  name: 'form-raas',
  data: () => {
    return {
      user: store.getters.getUser,
      title: 'Raas',
    }
  },

  created() {
  },
  computed: {
  },
  methods: {
    
  }
}
