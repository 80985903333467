<template>
    <div class="home">
        <h1 v-if="this.is_logged">Hi, {{ this.msg }}</h1>
        <h1 v-else>Home page</h1>
        <div class="center">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-heart"
                viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                    d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5ZM1 14V4h14v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1Zm7-6.507c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z" />
            </svg>
        </div>
    </div>
</template>
  

<style src="./homepage.css" scoped></style>
<script src="./homepage.view.js"></script>

 