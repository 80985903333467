<template>
    <div class="accordion" id="accordion-departments">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetable"
                    aria-expanded="true" aria-controls="collapseOne">
                    Departamentos y projectos del usuario
                </button>
            </h2>
            <div id="collapsetable" class="accordion-collapse" aria-labelledby="headingOne"
                data-bs-parent="#accordion-departments">
                <div class="accordion-body  overflow-auto">
                    <div class="card mb-3 table-departments  overflow-auto" id="table-departments">
                        <table class="table table-success table-striped">
                            <thead class="table-dark">
                                <tr>
                                    <th scope="col">Id Departamento</th>
                                    <th scope="col">Departamento</th>
                                    <th scope="col">Proyectos</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in departments_projects" :key=index>
                                    <td>{{ item.id }}</td>
                                    <td>{{ item.name }}</td>
                                    <td >
                                        <label v-for="(p, index_p) in item.projects" :key=index_p class="btn btn-outline-success">{{ p.name }} </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style src="./tabledepartmentsprojectsuser.css" scoped></style>
<script src="./tabledepartmentsprojectsuser.view.js"></script>