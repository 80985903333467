import amplify from '@/common/amplify'
import store from '@/store'

export default {
    async sign_in() {
        let is_logged = store.getters.isLoggedIn
        if(is_logged){
            console.log("Está ya loggeado")
            return
        }
        console.log("Inicio de log in")
        amplify.auth_federated_sign_in()

    },
    sign_out(){
        amplify.sign_out()
    }
}