<template>
      <TableUsers class="col-sm-12" :users="users" v-on:set-current-user="setCurrentUser"></TableUsers>
    <div class="time-tracking">
        <div class="center">
            <UserProfile v-if="selected_user" :user=this.selected_user></UserProfile>
        </div>
    </div>
    <div class="date-tracking input-group">
        <div class ="col-sm-6" >
            <p>Fecha inicio:</p>
        </div>
        <VueDatePicker class ="col-sm-6" v-model="date" type="date" :enable-time-picker="false" locale="es" :preview-format="this.format"
            :format="this.format" @update:model-value="onChangeDatePicker"></VueDatePicker>
    </div>
    <div class="date-tracking input-group">
        <div class ="col-sm-6" >
            <p>Fecha final:</p>
        </div>
        <VueDatePicker class ="col-sm-6" v-model="date_final" type="date" :enable-time-picker="false" locale="es" :preview-format="this.format"
            :format="this.format" @update:model-value="onChangeDatePickerFinal"></VueDatePicker>
    </div>
    <button class="btn btn-outline-secondary col-sm-3 mb-3" type="button" @click="getHoursUser">Obtener horas</button>
    <button v-if="availabe_csv" class="btn btn-outline-secondary col-sm-3 mb-3" type="button" @click="get_csv">CSV</button>

    <div class="">
        <TableTrackings :collapse=false :trackings=this.user_tracking></TableTrackings>
    </div>
    
</template>
  

<style src="./timetrackingusers.css" scoped></style>
<script src="./timetrackingusers.view.js"></script>