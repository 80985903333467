import store from '@/store'

export default {
  data: () => {
    return {
    };
  },
  computed: {
    is_logged() {
      return store.getters.isLoggedIn
    },
    get_name() {
      return store.getters.getUserName
    },
    is_admin() {
      if (store.getters.getAdmin) {
        return true
      }
      return false
    },
    is_super_admin() {
      if (store.getters.getAdmin != null && store.getters.getAdmin != undefined) {
        return store.getters.getAdmin.superadmin
      }
      return false
    }

  }
};
