<template>
    <div class="accordion" id="accordion-tracking">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetable"
                    aria-expanded="true" aria-controls="collapseOne">
                    Listado de horas
                </button>
            </h2>
            <div :class="{ 'collapse': collapse  }" id="collapsetable" class="accordion-collapse " aria-labelledby="headingOne"
                data-bs-parent="#accordion-tracking">
                <div class="accordion-body">
                    <div class="card mb-3 table-tracking co" id="table-tracking">
                        <table class="table table-success table-striped">
                            <thead class="table-dark">
                                <tr>
                                    <th scope="col">Usuario</th>
                                    <th scope="col">Departamento</th>
                                    <th scope="col">Proyecto</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Minutos</th>
                                    <th scope="col">Tipo de hora</th>
                                    <th scope="col">Inicio</th>
                                    <th scope="col">Final</th>
                                    <th scope="col">Comentarios</th>
                                    <th scope="col">Registro creado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in trackings" :key=index
                                    :class="{ 'row-warning': item.typology != 1 }">
                                    <td>{{ item.user_name }}</td>
                                    <td>{{ item.department_name }}</td>
                                    <td>{{ item.project_name }}</td>
                                    <td>{{ item.date }}</td>
                                    <td>{{ item.minutes }}</td>
                                    <td>{{ item.typology_name }}</td>
                                    <td>{{ item.time_start }}</td>
                                    <td>{{ item.time_end }}</td>
                                    <td>{{ item.notes }}</td>
                                    <td>{{ item.created_at }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style src="./tabletrackings.css" scoped></style>
<script src="./tabletrackings.view.js"></script>