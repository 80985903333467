<template>
    <div class="accordion" id="accordion-departments">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetable"
                    aria-expanded="true" aria-controls="collapseOne">
                    Departamentos
                </button>
            </h2>
            <div id="collapsetable" class="accordion-collapse" aria-labelledby="headingOne"
                data-bs-parent="#accordion-departments">
                <div class="accordion-body">
                    <div class="card mb-3 table-departments" id="table-departments">
                        <table class="table table-success table-striped">
                            <thead class="table-dark">
                                <tr>
                                    <th scope="col">Id</th>
                                    <th scope="col">Departamento</th>
                                    <th scope="col">Registro creado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in departments" :key=index>
                                    <td>{{ item.id }}</td>
                                    <td>{{ item.label }}</td>
                                    <td>{{ item.created_at }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style src="./tabledepartments.css" scoped></style>
<script src="./tabledepartments.view.js"></script>