/* eslint-disable */
import store from '@/store'
import apiAIT from '@/common/ateneaInvoiceToolsApi'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    components: { VueDatePicker },
    name: 'TimeTracking',
    data: () => {
        return {
            user_name: store.getters.getUserName,
            user: store.getters.getUser,
            deparments_and_projects: [],
            date: new Date(),
            old_date: new Date(),
            user_tracking:[],
            array_minutes_hours_tracking:[],
        }
    },

    created() {
    },
    computed: {},
    async mounted() {
        this.deparments_and_projects = await this.get_user_d_p()
        this.user_tracking = await this.get_trackings()
        console.log("this", this.deparments_and_projects)

    },
    methods: {
        async get_user_d_p() {
            if (this.user != null && this.user != undefined) {
                let array_dp = await apiAIT.getUserDepartmentsProjects(this.user.user.id)
                array_dp.forEach(element => {
                    console.log(">>", element)
                    element.projects.forEach(project => {
                        console.log(">>", project)
                        project.hours = null
                        project.minutes = null
                        project.start = null
                        project.end = null
                        project.notes = null
                    });
                });
                return array_dp
            }
            return []
        },
        async get_trackings() {
            if (this.user != null && this.user != undefined) {
                let result = await apiAIT.getTimeTracking(this.user.user.id, this.format_ddbb(this.date))
                let array_tracking = result.trackings
                let array_minutes_hours = result.minutes_hours_list
                // this.hours = result.hours
                // this.minutes = result.minutes
                console.log("array_tracking", array_tracking)
                console.log("array_minutes_hours", array_minutes_hours)
                this.array_minutes_hours_tracking = array_minutes_hours
                return array_tracking
            }
            return []
        },
        async save() {
            console.log("Info", this.deparments_and_projects)
            if (this.date == null || this.date == undefined) {
                alert("Es necesario indicar la fecha de las horas registradas")
                return
            }
            if(this.date > new Date()){
                alert("No es posible registrar horas a futuro.")
                return
            }
            console.log("Fecha", this.format(this.date))
            let items_params = []
            this.deparments_and_projects.forEach(element => {
                element.projects.forEach(project => {
                    console.log("minutes", project.minutes)
                    let minutes = 0
                    if (project.minutes != null && project.minutes != undefined) {
                        minutes = Number(project.minutes)
                    }

                    if(project.start != null && project.end != null){
                        if(project.start.length != 0 && project.end.length != 0){
                            let result_minutes = this.get_difference_start_end_return_minutes(project.start, project.end)
                            console.log("minutes", minutes, "resultmin", result_minutes)
                            if(minutes != result_minutes){
                                if (confirm(`Se ha cambiado manualmente los minutos del proyecto ${project.name}[${element.name}]. La diferencia entre ${project.start} y ${ project.end} es ${result_minutes}. ¿Desea cambiarlo? Si no, se cancelará el proceso.`) == true) {
                                    minutes = result_minutes
                                  } else {
                                    items_params = []
                                    return
                                  }
                            }
                        }
                    }
                    if (minutes > 0) {
                        items_params.push(
                            {
                                user_id: this.user.user.id,
                                department_id: element.id,
                                project_id: project.id,
                                date: this.format_ddbb(this.date),
                                minutes: minutes,
                                notes: project.notes,
                                start: project.start,
                                end: project.end
                            }
                        )
                    }
                });
            });
            
            if(items_params.length==0){
                alert("No hay horas que registrar")
                return
            }
            let json = {
                trackings: items_params
            }
            console.log(json)

            await apiAIT.postTimeTracking(json).then(async(r)=>{
                let alert_str = ''
                console.log("Resultado",r)
                r.forEach(element => {
                    if(element.code != 200){
                        alert_str += `Hubo un error al registrar las horas: ${element.info}`
                    }
                });
                if(alert_str.length==0){
                    alert_str = "Registro completado"
                    this.deparments_and_projects = await this.get_user_d_p()
                }
                alert(alert_str)
            }).catch((err)=>{
                console.error(err)
                alert("Se ha producido un error al registrar las horas")
            })

            this.user_tracking = await this.get_trackings()
        },
        format_ddbb(date) {
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

        },
        format(date) {
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
        },
        NumbersOnly(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            }else if(charCode == 46 || charCode == 44){
                evt.preventDefault(); //TEMPORAL COMA Y PUNTO
            } else {
                return true;
            }
        },
        get_time_difference(inicio, fin){
            console.log("Inicio", inicio)
            console.log("fin", fin)
            if(inicio>fin){
                return 0
            }
            let diffMs = fin.getTime()-inicio.getTime()
            return  Math.round((diffMs) / 60000)
        },
        handleBlur(index_deparment, index_project) {
            console.log('blur',index_deparment, index_project)
            let project = this.deparments_and_projects[index_deparment].projects[index_project]
            let start = project.start
            let end = project.end
            //format
            if(start != null && 0< start.length && start.length<=5){
                start = start.padEnd(5, 0)
                console.log(start)
                this.deparments_and_projects[index_deparment].projects[index_project].start = start
            }
            if(end != null && 0< end.length && end.length<=5){
                end = end.padEnd(5, 0)
                console.log(end)
                this.deparments_and_projects[index_deparment].projects[index_project].end = end
            }
            // CALCULAR DIFERENCIA
            console.log("Project", project)
            if(project.start != null && project.end !=null){
                if(project.start.length != 0 && project.end.length !=0){
                    start = start.substring(0, 2) + ":" + start.substring(2 + 1);
                    end = end.substring(0, 2) + ":" + end.substring(2 + 1);
                    let minutos = this.get_difference_start_end_return_minutes(start, end)
                    console.log("MInutos", minutos)
                    this.deparments_and_projects[index_deparment].projects[index_project].minutes = minutos
                }
            }
        },
        get_difference_start_end_return_minutes(start, end){
            let fecha_inicio = structuredClone(this.date)
            let fecha_fin = structuredClone(this.date)
            let arr_start = start.split(':')
            let arr_end = end.split(':')
            fecha_inicio.setHours(arr_start[0], arr_start[1], 0)
            fecha_fin.setHours(arr_end[0], arr_end[1], 0)
            return this.get_time_difference(fecha_inicio, fecha_fin)
        },
        async onChangeDatePicker(date) {
            console.log("Previo", this.old_date)
            console.log("Nuevo", date)
            if(date == null || date == undefined){
                return
            }
            if(this.format_ddbb(date) == this.format_ddbb(this.old_date)){
                console.log("No se cambia")
                return
            }
            this.old_date = date
            console.log("Change datepicker")
            this.user_tracking = await this.get_trackings()
        }
    },
}
