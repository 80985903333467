<template>
    <div class="timer">
        <p v-if="show_seconds">{{ tt_seconds }}</p>
        <div class="container-display">
            <div class="display">
                <p>
                    <span id="number hours">{{ hours }}</span>:<span id="number minutes">{{ minutes }}</span>:<span
                        id="number seconds">{{ seconds }}</span>
                </p>
            </div>
        </div>
        <div class="btn-container">
            <button :disabled="this.running" type="button" class="btn btn-success" @click="start">Start</button>
            <button v-if="show_pause_button" :disabled="!this.running" type="button" class="btn btn-warning"
                @click="pause">Pause</button>
            <button :disabled="!this.running" type="button" class="btn btn-danger" @click="stop">Stop</button>
        </div>
    </div>
</template>

<style src="./timeruser.css" scoped></style>
<script src="./timeruser.view.js"></script>