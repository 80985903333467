/* eslint-disable */
import store from '@/store'
import apiAIT from '@/common/ateneaInvoiceToolsApi'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    components: { VueDatePicker },
    name: 'TimeTrackingUsers',
    data: () => {
        return {
            users: [],
            selected_user: null,
            date: new Date(),
            old_date: new Date(),
            date_final: new Date(),
            old_date_final: new Date(),
            user_tracking: [],
            availabe_csv:false,
        }
    },

    created() {
    },
    computed: {},
    async mounted() {
        await this.get_users()
    },
    methods: {
        async get_trackings() {
            if (this.selected_user != null && this.selected_user != undefined) {
                const loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                    loader: 'dots'
                });

                await apiAIT.getTimeTracking(this.selected_user.user.id, this.format_ddbb(this.date), this.format_ddbb(this.date_final)).then((result) => {
                    console.log(result)
                    if (result) {
                        let array_tracking = result.trackings
                        let array_minutes_hours = result.minutes_hours_list
                        console.log("array_tracking", array_tracking)
                        console.log("array_minutes_hours", array_minutes_hours)
                        this.user_tracking = array_tracking
                        if(this.user_tracking.length>0){
                            this.availabe_csv = true
                        }else{
                            this.availabe_csv = false
                        }
                    }
                }).catch((err) => {
                    console.error("get_trackings", err)
                    this.availabe_csv = false
                }).finally(() => {
                    loader.hide()
                })


            } else {
                this.user_tracking = []
                this.availabe_csv = false
            }
        },
        format_ddbb(date) {
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        },
        format(date) {
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
        },
        async onChangeDatePicker(date) {
            console.log("Previo", this.old_date)
            console.log("Nuevo", date)
            if (date == null || date == undefined) {
                return
            }
            if (this.format_ddbb(date) == this.format_ddbb(this.old_date)) {
                console.log("No se cambia")
                return
            }
            this.old_date = date
            console.log("Change datepicker")
        },
        async onChangeDatePickerFinal(date) {
            console.log("Previo", this.old_date_final)
            console.log("Nuevo", date)
            if (date == null || date == undefined) {
                return
            }
            if (this.format_ddbb(date) == this.format_ddbb(this.old_date_final)) {
                console.log("No se cambia")
                return
            }
            this.old_date_final = date
            console.log("Change datepicker")
        },
        async getHoursUser() {
            await this.get_trackings()
        },
        async setCurrentUser(user) {
            console.log("RECIBIDO", user)
            this.user_tracking = []
            this.availabe_csv = false
            if (user) {
                this.selected_user = user
            }
        },
        async get_users() {
            const loader = this.$loading.show({
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: false,
                onCancel: this.onCancel,
                loader: 'dots'
            });
            await apiAIT.getUser().then((r) => {
                console.log(r)
                if (r) {
                    this.users = r
                }
            }).catch((err) => {
                console.error("get_users", err)
            }).finally(() => {
                loader.hide()
            })

        },
        async get_csv(){
            this.format_ddbb(this.date), this.format_ddbb(this.date_final)
            console.log(this.user_tracking)
            let info = "usuario,fecha,departamento,tarea,minutos,tipo_hora,notas\n"
            this.user_tracking.forEach(e => {
                info +=  e.user_name+','+e.date+','+e.department_name+','+e.project_name+','+e.minutes+','+e.typology_name+','+e.notes+'\n'
            });
            var blob = new Blob([info], { type: 'text/csv;charset=utf-8;' });
            var url = URL.createObjectURL(blob);
            var pom = document.createElement('a');
            let user_file = `./${this.selected_user.user.name.toLowerCase().replaceAll(' ', '')}__${this.format_ddbb(this.date).replaceAll('-', '_')}__${this.format_ddbb(this.date_final).replaceAll('-', '_')}.csv`
            pom.href = url;
            pom.setAttribute('download', user_file);
            pom.click();

        }
    },
}
