<template>
    <nav class="navbar navbar-dark bg-success">
        <div class="container-fluid">
            <a :href="'/'" class="navbar-brand">
                <label v-if="is_super_admin">
                    <i class="fa fa-user-secret" aria-hidden="true"></i>
                </label>
                <label v-else-if="is_admin">
                    <i class="fa fa-user-plus" aria-hidden="true"></i>
                </label>

                ATENEA
            </a>
            <div class="d-flex">
                <div class="form-group" v-if="is_logged">
                    <span id="lblHeaderNombre" class="text-left control-label">{{ get_name }}</span>
                </div>

                <div class="form-group nav-link" v-if="is_logged">@
                    <router-link :to="{ name: 'logout' }">
                        Cerrar Sesión
                    </router-link>
                </div>

                <div class="form-group nav-link" v-if="!is_logged">
                    <router-link :to="{ name: 'SignIn' }">
                        Iniciar Sesión
                    </router-link>
                </div>
            </div>
        </div>
    </nav>
</template>

<style src="./navigationbar.css" scoped></style>
<script src="./navigationbar.view.js"></script>
