<template>
  <div class="form-callcenter">
    <h1>{{ title }}</h1>
    <button class="collapse-icon btn btn-link"  
    type="button" data-bs-toggle="collapse" data-bs-target="#info" aria-expanded="false" aria-controls="info">
      <i class="fa-solid fa-circle-info"></i>
    </button>
    <div class="mb-3 input-group text-start collapse" id="info">
      En esta página puede subir un fichero excel con los datos de RAAS para ser volcados a la base de datos. Pasos:
      
      <ol class="list-group list-group-flush">
        <li class="list-group-item"> <strong>1.</strong> Seleccione el fichero</li>
        <li class="list-group-item"> <strong>2.</strong> Haga click en "Subir"</li>
      </ol>
    </div>

    <div class="text-start mb-3">
      <label for="callcenterfile" class="col-sm-5 text-success fw-bold form-label">Seleccionar archivo</label>
      <input class="form-control" type="file" id="callcenterfile">
    </div>
  </div>
    <div class="">
  <button type="button" @click="save" class="btn btn-outline-primary">Procesar archivo</button>
</div>

</template>
  

<style src="./formraas.css" scoped></style>
<script src="./formraas.view.js"></script>

 