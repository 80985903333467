<template>
    <div class="accordion" id="accordion-users">
        <div class="accordion-item">
            <h2 class="accordion-header" id="heading-users">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetable-user"
                    aria-expanded="true" aria-controls="heading-users">
                    Users
                </button>
            </h2>
            <div id="collapsetable-user" class="accordion-collapse" aria-labelledby="headingOne"
                data-bs-parent="#accordion-users">
                <div class="accordion-body  overflow-auto">
                    <div class="card mb-3 table-users  overflow-auto" id="table-users">
                        <table class="table table-success table-striped">
                            <thead class="table-dark">
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Nombre</th>
                                    <!-- <th scope="col">Email</th>
                                    <th scope="col">Username</th>-->
                                    <th scope="col">Activo</th> 
                                    <th scope="col">Administrador</th>
                                    <th scope="col">Departamentos</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in users" :key=index>
                                    <td>
                                        <button type="button" class="btn btn-success" v-on:click="setCurrentUser(item)"><i class="fa-regular fa-square-check"></i></button>
                                    </td>
                                    <td>{{ item.user.name }}</td>
                                    <!-- <td>{{ item.user.email }}</td>
                                    <td>{{ item.user.username }}</td> -->
                                    <td>{{ item.user.active }}</td>
                                    <td>
                                        <label v-if="item.admin && item.admin.superadmin">SuperAdmin</label>
                                        <label v-else-if="item.admin">Admin</label>
                                    </td>
                                    <td>
                                        <label v-for="(p, index_p) in item.departments" :key=index_p
                                            class="btn btn-outline-success">{{ p.department_name }} </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style src="./tableusers.css" scoped></style>
<script src="./tableusers.view.js"></script>