<template>
    <div v-for="(day, index) in array_minutes_hours" :key="index" class="card card-resume" :class="this.rnd_color">
        <div class="card-header">Tiempo registrado</div>
        <div class="card-body">
            <div class="row card-text">
                <div v-if="array_minutes_hours.length > 1" class="col-sm text-md-center">
                    <label class="text-success fw-bold">Fecha:</label> {{ day.date }}
                </div>
                <div class="row card-text">
                </div>
                <div class="col-sm text-md-center">
                    <label class="text-success fw-bold">Minutos:</label> {{ day.sum_minutes }}
                </div>
                <div class="col-sm text-md-center">
                    <label class="text-success fw-bold">Horas:</label> {{ day.sum_hours }}
                </div>
            </div>
        </div>
    </div>
</template>

<style src="./resumetrackings.css" scoped></style>
<script src="./resumetrackings.view.js"></script>