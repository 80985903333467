<template>
  <div class="form-invoice-fus">
    <h1>{{ title }}</h1>
    <div class="text-start mb-3">
      <label for="invoicefus" class="col-sm-5 text-success fw-bold form-label">Seleccionar archivo</label>
      <input class="form-control" type="file" id="invoicefus">
    </div>
  </div>
    <div class="">
  <button type="button" @click="save" class="btn btn-outline-primary">Procesar archivo</button>
</div>

</template>
  

<style src="./forminvoiceFUS.css" scoped></style>
<script src="./forminvoiceFUS.view.js"></script>

 