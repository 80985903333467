<template>
    <div class="fixed-timer">
        <div class="header-fixed-timer">
            <button class="btn-closed" type="button" v-on:click="toggleTimer()"><i class="fa fa-times-circle"
                    aria-hidden="true"></i></button>
        </div>
            <div key="1" :class="{ 'timer-hidden': !display_timer }" class="body-fixed-timer">
                <TimerUser :fixed="true"></TimerUser>
            </div>
    </div>
</template>

<style src="./fixedtimeruser.css" scoped></style>
<script src="./fixedtimeruser.view.js"></script>