/* eslint-disable */
import VueSelect from 'vue-select'
import apiAIT from '@/common/ateneaInvoiceToolsApi'
import store from '@/store'

export default {
    name: 'TimerView',
    components: {
        VueSelect,

    },
    data: () => {
        return {
            user: store.getters.getUser,
            selected_department: null,
            departments: [],
            selected_project: null,
            projects: [],
            selected:false,
        }
    },

    created() {
        if(this.timer_is_running){
            this.selected_department = store.getters.getDepartment
            this.selected_project = store.getters.getProject
            this.selected = true
        }
    },
    computed: {
        timer_is_running(){
            this.selected_department = store.getters.getDepartment
            this.selected_project = store.getters.getProject
            if(store.getters.getDepartment&&store.getters.getProject){
                this.selected = true
            }else{
                this.selected = false
            }

            return store.getters.getRunning
        }
    },
    async mounted() {
        this.departments = await this.get_user_departments()
    },
    methods: {
        select_department_project() {
            console.log("seleccionar", this.selected_department, this.selected_project)
            if (this.selected_department != null && this.selected_department != undefined &&
                this.selected_project != null && this.selected_project != undefined) {
                store.commit('setDepartment', this.selected_department)
                store.commit('setProject', this.selected_project)
                this.selected = true
            }
        },
        async get_user_departments() {
            if (this.user != null && this.user != undefined) {
                let array_dp = await apiAIT.getUserDepartmentsProjects(this.user.user.id)
                array_dp.forEach(element => {
                    console.log(">>", element)
                    element.projects.forEach(project => {
                        console.log(">>", project)
                    });
                });
                return array_dp
            }
            return []
        },
        cancelar_seleccion(){
            this.selected_department = null
            this.selected_project = null
            this.projects = []
            store.commit('setDepartment', this.selected_department)
            store.commit('setProject', this.selected_project)
            this.selected = false
        }
    },
    watch: {
        selected_department() {
            if (this.selected_department == null && this.selected_department == undefined) {
                this.projects = []
                this.selected_project = null
                
                store.commit('setDepartment', null)
                store.commit('setProject', null)
                return
            }
            console.log("selected_department", this.selected_department)
            this.projects = []
            this.projects = this.selected_department.projects

        },
        selected_project() {
            console.log("selected_project", this.selected_project)
            if (this.selected_project == null && this.selected_project == undefined) {
                store.commit('setProject', null)
            }

        }
    }
}
