/* eslint-disable */
import store from '@/store'
import apiAIT from '@/common/ateneaInvoiceToolsApi'

export default {
  name: 'form-invoice',
  props: { filetype: String },
  data: () => {
    return {
      user: store.getters.getUser,
      title: 'Facturación SAGE',
      display_info:false,
    }
  },

  created() {

  },
  computed: {
  },
  methods: {
    toggle_info(){
      console.log("click")
      this.display_info = !this.display_info
    }

  }
}
