<template>
  <div class="form-callcenter">
    <h1>{{ title }}</h1>
    <div class="text-start mb-3">
      <label for="callcenterfile" class="col-sm-5 text-success fw-bold form-label">Seleccionar archivo</label>
      <input class="form-control" type="file" id="callcenterfile">
    </div>
  </div>

  <div class="">
    <button type="button" @click="save" class="btn btn-outline-primary">Procesar archivo</button>
  </div>
</template>
  

<style src="./formsagepaymentsupdate.css" scoped></style>
<script src="./formsagepaymentsupdate.view.js"></script>

 