/* eslint-disable */
import store from '@/store'

export default {
  name: 'admin-user',
  data: () => {
    return {

    }
  },

  created() {
  },
  computed: {
  },
  methods: {

  }
}
